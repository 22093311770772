.Toastify__toast-container {
  width: auto;
}

.Toastify__toast {
  min-height: auto;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}
